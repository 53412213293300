<template>
  <div :class="{ 'has-error': validation.hasError('amount') }" class="form--group row">
    <div class="col-12 col-md-6" :class="{ 'pb-0': $mq === 'xs' }">
      <div class="custom-control custom-checkbox my-2">
        <input
          @click="toggleFacility"
          type="checkbox"
          class="custom-control-input"
          :value="id"
          :id="'facility-with-unit-field-' + id"
          :checked="selected"
          name="attributes[]"
        />
        <label class="custom-control-label" :for="'facility-with-unit-field-' + id">{{
          name
        }}</label>
      </div>
    </div>
    <div class="col-12 col-md-6" :class="{ 'pb-0': $mq === 'xs' }">
      <div class="input--group">
        <input
          :value="amount"
          @input="facilityAmountChanged"
          name="amounts[]"
          class="basic--input"
          type="text"
          placeholder=""
          aria-describedby="basic-addon1"
        />
        <div class="append--right">
          {{ unitName }}
        </div>
      </div>
      <div class="val-error" v-if="validation.hasError('amount')">
        {{ validation.firstError('amount') }}
      </div>
    </div>
  </div>
</template>

<script>
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'facility-with-unit-field',
  props: ['name', 'selected', 'id', 'unitName', 'amount'],
  mixins: [HelperMixin],
  methods: {
    toggleFacility() {
      let self = this;
      self.$store.commit('listingForm/sect2/detail/TOGGLE_SELECTED_FACILITY', this.id);
    },
    facilityAmountChanged($event) {
      let self = this;
      self.$store.commit('listingForm/sect2/detail/SET_FACILITY_AMOUNT', {
        facilityId: self.id,
        amount: this.cleanInput($event.target.value),
      });
      if (!this.selected) {
        this.toggleFacility();
      }
    },
    validate: function () {
      return this.$validate().then(
        function (success) {
          return !!success;
        }.bind(this),
      );
    },
  },
  watch: {
    selected() {
      this.validation.reset();
    },
  },
  validators: {
    amount(value) {
      if (this.selected) {
        return Validator.value(value)
          .required(this.$i18n.t('errors.facilityAmount.required'))
          .greaterThan(0, this.$i18n.t('errors.facility.min', { min: 1 }));
      }
    },
  },
};
</script>

<style scoped></style>
